import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./customer-popup.scss";

export class CustomerPopupComponent extends Component {

    public boxContainer: Component;
    public closeButton: Component;
    public actionButton: Component;

    constructor(p: {
        title: string,
        content: string,
        buttonText: string,
        buttonUrl: string,
        large?: boolean,
        cases?: { title: string, text: string, icon: string }[]
    }) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentCustomerPopup}">
                <div class="${s.box}">
                    <div class="${s.head}">
                        <div class="${s.title}">${p.title}</div>
                        <div class="${s.close}">
                            <div class="${s.icon}"></div>
                        </div>
                    </div>
                    <div class="${s.content}">
                        <div class="${s.icon}"></div>
                        <div class="${s.text}">
                            ${p.content}
                        </div>
                    </div>
                    <div class="${s.cases}">
                        ${p.cases ? p.cases.map(value => {
                            //language=HTML
                            return `
                            <div class="${s.case} ${s[value.icon]}">
                                <div class="${s.head}">
                                    <div class="${s.round}">
                                        <div class="${s.icon}"></div>
                                    </div>
                                    ${value.title}
                                </div>
                                <div class="${s.text}">
                                    ${value.text}
                                </div>
                            </div>
                            `;
                        }).join("") : ``}
                    </div>
                    <a href="${p.buttonUrl}" target="_blank" class="${s.button}">
                        ${p.buttonText}
                        <div class="${s.icon}"></div>
                    </a>
                </div>
            </div>
        `;
    }

    commit() {
        this.boxContainer = this.el(s.box);
        this.actionButton = this.el(s.button);
        this.closeButton = this.el(s.close);

        this.boxContainer.translate({translateY: 0, scale: 1, delay: 300});
        this.closeButton.onClick(async () => {
            await this.boxContainer.translate({translateY: 500, opacity: 0, delay: 300});
            this.remove();
            this.onClose();
        });

        super.commit();
    }

    onClose() {

    }

}