@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteBlackFlow {
  display: flex;
  flex-direction: column;
  color: var(--black);
  gap: 30px;

  .title {
    color: var(--web-purple-text);
    text-transform: uppercase;
    font-size: 16px;

    @include texts.font("semi-bold");
  }

  .bigTitle {
    max-width: 900px;
    font-size: 26px;

    @include texts.font("semi-bold");
  }

  .contents {
    display: flex;
    gap: 70px;

    .imageContainer {
      width: 50%;
      background: var(--grey-sur);
      border-radius: 16px;
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 1;

      .image {
        height: 650px;
        border-radius: 16px;
        border: 2px solid #EEE;
        box-shadow: var(--web-image-shadow);
        position: relative;
      }

      .imgShadow {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100px;
        height: 100px;
        background: #8C62FE;
        filter: blur(95px);
      }
    }

    .texts {
      display: flex;
      flex-direction: column;
      gap: 40px;

      &.textCollapse {
        gap: 25px;
      }

      .entry {
        display: flex;
        flex-direction: column;
        gap: 15px;
        cursor: pointer;
        transition: 0.1s;

        &.canCollapse {
          border-radius: 18px;
          background: #FFF;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.11);
          padding: 20px;
          transform: scale(1.05);

          &:hover {
            .name {
              color: var(--web-purple-text);
            }
          }

          .arrow {
            display: block;
          }
        }

        &.collapsed {
          transform: scale(1.0);

          .description {
            display: none;
          }

          .arrow {
            transform: rotate(90deg);
          }
        }

        .nameContainer {
          display: flex;
          align-items: center;

          .name {
            font-size: 20px;
            transition: 0.1s;

            @include texts.font("semi-bold");

            .icon {
              width: 20px;
              height: 20px;
              display: inline-block;
              top: 3px;
              position: relative;

              &.aiWrite {
                @include icons.ico("black-ai-write");
              }

              &.aiStars {
                @include icons.ico("black-ai-stars");
              }

              &.colorPurple {
                filter: var(--icon-purple-black-web);
              }
            }
          }

          .arrow {
            height: 16px;
            width: 16px;
            flex: 0 0 16px;
            display: none;

            @include icons.ico("black-bottom-bold");
          }
        }


        .description {
          font-size: 15px;
          color: var(--grey-text);
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .imageContainer {
      .image{
        height: 630px !important;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .imageContainer {
      .image{
        height: 550px !important;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .imageContainer {
      .image{
        height: 540px !important;
      }
    }
  }


  @media screen and (max-width: 930px) {
    .contents {
      flex-direction: column-reverse;

      .imageContainer {
        width: 100%;

        .image {
          width: 100%;
          height: unset !important;
        }
      }
    }
  }
}