@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteBlackPlatform {
  display: flex;
  flex-direction: column;
  gap: 35px;

  .description {
    font-size: 28px;
    position: relative;
    max-width: 800px;
    z-index: 1;

    @include texts.font("semi-bold");

    span {
      color: var(--web-text-white-alpha);
    }

    .content {
      position: relative;
    }
  }

  .subDescription {
    border-top: 1px solid var(--web-blue);

    .blur {
      width: 100%;
      height: 36px;
      background: var(--web-blue);
      filter: blur(60px);
    }

    .content {
      position: relative;
      max-width: 700px;

      span {
        color: var(--web-blue);
      }
    }
  }
}