@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentWebsiteProductGrid {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .component {
    display: flex;
    flex-direction: column;
    gap: 17px;

    .top {
      display: flex;
      flex-direction: row;
      gap: 36px;

      .icon {
        margin-top: 8px;
        width: 40px;
        height: 40px;
        @include icons.ico("illus-circle");
      }

      .title {
        font-size: 44px;
        @include texts.font("bold");
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      gap: 100px;
      min-height: 300px;

      .left {
        border-left: 8px solid var(--grey-sur);
        margin-left: 16px;
        padding-bottom: 50px;

        .texts {
          margin-left: 54px;
          display: flex;
          flex-direction: column;
          gap: 50px;

          .text {
            font-size: 23px;
            @include texts.font("semi-bold");

            span {
              background: var(--grad-orange-button);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          .button {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            width: fit-content;

            .textButton {
              width: calc(100% - 50px);
              padding: 0 0 0 10px;
              text-align: right;
              background: var(--grad-orange-button);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 23px;
              @include texts.font("bold");
            }

            .iconButton {
              margin: 0 10px 0 5px;
              width: 18px;
              height: 18px;
              filter: var(--icon-orange);
              @include icons.ico("black-right-arrow");
            }

            &:hover {
              background: var(--grey-sur);
              border-radius: 20px;
            }
          }
        }
      }

      .right {
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 38%;
        min-width: 38%;

        img {
          max-width: 100%;
          max-height: 70%;
        }
      }
    }
  }

  @media (max-width: 480px) {
    gap: 25px;

    .component {
      gap: 17px;

      .top {
        flex-direction: row;
        gap: 15px;

        .icon {
          margin-top: 2px;
          width: 30px;
          height: 30px;
        }

        .title {
          font-size: 25px;
        }
      }

      .bottom {
        flex-direction: column;
        gap: 20px;
        border-left: 6px solid var(--grey-sur);
        margin-left: 12px;
        padding-bottom: 0;

        .left {
          border-left: none;
          padding-bottom: 0;

          .texts {
            margin-left: 13px;
            gap: 20px;

            .text {
              font-size: 16px;
            }

            .button {
              width: 100%;
              gap: 15px;
              padding: 0;

              .textButton {
                width: calc(100% - 30px);
                padding: 0;
                font-size: 16px;
              }

              .iconButton {
                margin: 0;
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        .right {
          padding: 20px 0 20px 40px;
          max-width: calc(100% - 40px);
          min-width: calc(100% - 40px);

          img {
            width: 100%;
            height: 70%;
          }
        }
      }
    }
  }
}