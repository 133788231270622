import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "./website-content-publisher.scss";
import * as i from "./../../columns/steps/website-columns-steps.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {WebsitePresentationLightComponent} from "../../presentation/light/WebsitePresentationLightComponent";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Network} from "../../../../../../../network/Network";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteColumnsStepsComponent} from "../../columns/steps/WebsiteColumnsStepsComponent";

export class WebsiteContentPublisherComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductComponent({
                            title: Resources.t("words.communicationContinuous"),
                            text: Resources.t("words.publisherDescription"),
                            productType: ProductType.PUBLISHER,
                            url: `${Network.vendor}images/capture-publisher-agenda.webp`
                        }))}
                    </div>
                    <div class="${r.separation}"></div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsStepsComponent({
                            title: Resources.t("words.whatDo"),
                            grid: [{
                                name: Resources.t("words.create"),
                                description: Resources.t("words.createPublication"),
                                icon: i.pensil
                            }, {
                                name: Resources.t("words.collaborate"),
                                description: Resources.t("words.collaboratePublish"),
                                icon: i.collaboration
                            }, {
                                name: Resources.t("words.publish"),
                                description: Resources.t("words.publishEverywhere"),
                                icon: i.letter
                            }
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.resumePublish"),
                            description: "",
                            image: `${Network.vendor}images/` +"illus-content-publisher.svg",
                            color: "black",
                            size: "medium"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.schedulePublications"),
                            description: Resources.t("words.schedulePublicationsDetails"),
                            image: `${Network.vendor}images/` +"capture-publisher.webp",
                            shadow: "shadow",
                            border: "border",
                            color: "green",
                            size: "tall"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.disponibleEverywhere"),
                            description: Resources.t("words.socialEngagement"),
                            image: `${Network.vendor}images/` +"illus-social.svg",
                            color: "red",
                            size: "medium"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationLightComponent({
                            title: Resources.t("words.personalizePosts"),
                            subtitle: Resources.t("words.customizeEveryPost"),
                            illus: "capture-publisher-creator",
                            size: "tall"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationLightComponent({
                            title: Resources.t("words.receiveMessage"),
                            subtitle: Resources.t("words.descriptionReceiveMessage"),
                            illus: "illus-funnel",
                            size: "tall",
                            url: Network.router.pages.getUrlWithPath('inbox'),
                            direction: true
                        }))}
                    </div>
                        <!--<div class="${s.container}">
                        $ {this.draw(new WebsiteMobileComponent())}
                    </div>-->
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("words.questions.publisher")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}