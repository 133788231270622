import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {WebsiteContentPublisherComponent} from "../components/content/pages/publisher/WebsiteContentPublisherComponent";

export class WebsitePublisherPage extends WebsitePageComponent {
    constructor() {
        super();
        this.updateMetas("publisher");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentPublisherComponent();
    }

    onHash(): void {

    }
}