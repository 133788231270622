@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteBlackWords {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .what {
    display: flex;
    align-items: center;
    gap: 15px;

    .ai {
      width: 32px;
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-self: center;

      .blur {
        position: absolute;
        height: 100%;
        width: 100%;
        background: var(--web-blue);
        filter: blur(13px);
      }

      .icon {
        position: absolute;
        width: 27px;
        height: 27px;

        @include icons.ico("3d-ai");
      }
    }

    .text {
      font-size: 16px;
      text-transform: uppercase;
      color: var(--web-blue);
    }
  }

  .numbers {
    display: flex;
    gap: 44px;
    margin-bottom: 63px;

    .number {
      position: relative;

      &.purple {
        .blur {
          background: var(--web-purple-text);
        }

        .text {
          color: var(--web-purple-text);
        }
      }

      &.blue {
        .blur {
          background: var(--web-blue);
        }

        .text {
          color: var(--web-blue);
        }
      }

      .integer {
        display: flex;
        align-items: center;
        position: relative;
        width: fit-content;

        .blur {
          height: 27px;
          width: 100%;
          position: absolute;
          filter: blur(22px);
        }

        .text {
          font-size: 44px;
          position: relative;

          @include texts.font("bold");
        }
      }

      .description {
        font-size: 16px;
        color: var(--white);
      }

      .line {
        width: 6px;
        height: 50px;
        border-radius: 8px;
        background: var(--web-blue);
        position: absolute;
        left: calc(50% - 3px);
        margin-top: 20px;
      }
    }
  }

  .separators {
    .separator {
      height: 1px;
      width: 100%;
      background: var(--web-blue);
    }

    .blur {
      height: 36px;
      width: 100%;
      background: var(--web-blue);
      filter: blur(85px);
    }
  }

  .explore {
    font-size: 16px;
    margin-bottom: 30px;

    @include texts.font("semi-bold");

    span {
      color: var(--web-blue);
    }
  }

  .charts {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .entry {
      .title {
        font-size: 14px;
        color: var(--white);

        @include texts.font("semi-bold");
      }

      .chart {
        display: flex;
        align-items: center;
        gap: 15px;

        &.blue {
          .progression {
            background: var(--web-blue);
          }
        }

        &.white {
          .progression {
            background: var(--white);
          }

          .value {
            color: var(--white-transparent);
          }
        }

        .bar {
          height: 6px;
          background: var(--white-transparent);
          width: 100%;
          overflow: hidden;
          border-radius: 6px;

          .progression {
            height: 100%;
            width: 50%;
          }
        }

        .value {
          font-size: 23px;
          color: var(--white);

          @include texts.font("bold");
        }
      }
    }
  }

  @media screen and (max-width: 930px) {
    .numbers {
      .integer {
        .text {
          font-size: 24px !important;
        }
      }
    }
  }
}