import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import {OfferContentComponent} from "../content/OfferContentComponent";
import * as s from "./offer-template.scss";
import {IOfferProductTemplateModel} from "../../../../../../models/offer/product/templates/IOfferProductTemplateModel";
import {Resources} from "../../../../../../resources/Resources";
import {
    IOfferProductBundleTemplateModel
} from "../../../../../../models/offer/product/bundles/IOfferProductBundleTemplateModel";
import {OfferProductLevel} from "../../../../../../models/offer/product/OfferProductLevel";
import {IOfferPeriodTypeModel} from "../../../../../../models/offer/IOfferPeriodTypeModel";
import {OfferPeriodType} from "../../../../../../models/offer/product/OfferPeriodType";
import {OfferTemplateIconComponent} from "./icon/OfferTemplateIconComponent";
import {OfferContents} from "../content/types/OfferContents";
import {IOfferContentsTable, IOfferContentsTableIcon} from "../content/types/IOfferContentsTable";
import {
    TooltipComponent
} from "../../../../../components/global/tooltip/TooltipComponent";

export class OfferTemplateComponent extends Component {

    public productTemplate: IOfferProductTemplateModel;
    public period: IOfferPeriodTypeModel;
    public table: IOfferContentsTable[];
    public bundle: IOfferProductBundleTemplateModel;

    public priceContainer: Component;
    public button: Component;
    public contentContainer: Component;

    constructor(template: IOfferProductTemplateModel, period: IOfferPeriodTypeModel, table: IOfferContentsTable[]) {
        super();
        this.productTemplate = template;
        this.period = period;
        this.table = table;
        this.bundle = template.bundles[0];

        //language=HTML
        this.template = `
            <div class="${s.componentOfferTemplate}">
                ${OfferContents.popularTemplates().includes(template.id) ? `<div class="${s.popular}">${Resources.t("words.popular")}</div>` : ``}
                <div class="${s.selectedCheck}"></div>
                <div class="${s.name}">
                    ${Resources.t(`words.offerName${template.type}`)}
                </div>
                <div class="${s.icon}">
                    ${this.draw(new OfferTemplateIconComponent(template.type))}
                </div>
                <div class="${s.price}"></div>
                <div class="${s.description}">
                    ${Resources.t(`words.offerDescription${template.type}`)}
                </div>
                <div class="${s.button}">
                    ${Resources.t("words.choose")} ${Resources.t(`words.offerName${template.type}`)}
                </div>
                <div class="${s.content}"></div>
            </div>
        `;
    }

    commit() {
        this.priceContainer = this.el(s.price);
        this.contentContainer = this.el(s.content);
        this.button = this.el(s.button);

        this.button.onClick(() => this.onPick());
        this.table.forEach(tableLine => {
            let entry = tableLine.entries.find(value => value.templateId == this.productTemplate.id);
            let entryCase;
            if (entry) {
                switch (tableLine.type) {
                    case "text":
                        //language=HTML
                        entryCase = this.contentContainer.append(`
                            <div class="${s.entry}">
                                <div class="${s.title}">${tableLine.name}
                                    ${this.contentContainer.draw(new TooltipComponent(tableLine.description))}
                                </div>
                                <div class="${s.description}">${entry.value}</div>
                            </div>
                        `);
                        break;
                    case "bool":
                    case "textBool":
                        //language=HTML
                        entryCase = this.contentContainer.append(`
                            <div class="${s.entry}">
                                <div class="${s.title}">
                                    ${entry.value ? `<div class="${s.boolTrue}"><div class="${s.icon}"></div></div>` : `<div class="${s.boolFalse}"></div>`}
                                    ${tableLine.name}
                                    ${this.contentContainer.draw(new TooltipComponent(tableLine.description))}
                                </div>
                            </div>
                        `);

                        if (tableLine.type == "textBool" && entry.value) {
                            entryCase.append(`<div class="${s.description}">${entry.value}</div>`);
                        }
                        break;
                }

                if (tableLine.icons) {
                    //language=HTML
                    entryCase.append(`
                        <div class="${s.icons}">
                            ${tableLine.icons.map(value => `<div class="${s.descIcon} ${s[IOfferContentsTableIcon[value].toLowerCase()]}"></div>`).join("")}
                        </div>
                    `);
                }
            }

        });

        this.renderPrice();
        super.commit();
    }

    renderPrice() {
        let price = this.bundle.priceInitial / 100;

        if (this.period.periodType == OfferPeriodType.ANNUAL) {
            price = price * 12;
            price = price - (price * this.period.advantagePercentage);
        }

        this.priceContainer.clearAll();
        this.priceContainer
            .setHtml(this.productTemplate.level == OfferProductLevel.FREE ? Resources.t("words.free") : `${Math.round(price)}€ <span>/${Resources.t(`words.offerPeriodTitle${this.period.periodType}`)}</span>`)
    }

    setPeriod(period: IOfferPeriodTypeModel) {
        this.period = period;
        this.renderPrice();
    }

    setSelected() {
        this.addClass(s.selected);
    }

    remSelected() {
        this.removeClass(s.selected);
    }

    onPick() {

    }
}