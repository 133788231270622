import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import {WebsiteReviewComponent} from "./review/WebsiteReviewComponent";
import * as s from "./website-reviews.scss";
import {IWebsiteReview} from "./types/IWebsiteReview";

export class WebsiteReviewsComponent extends Component {
    constructor(title: string, reviews: IWebsiteReview[]) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteReviews}">
                <div class="${s.title}">${title}</div>
                <div class="${s.reviews}">
                    ${reviews.map(value => this.draw(new WebsiteReviewComponent(reviews.length, value))).join("")}
                </div>
            </div>
        `;
    }
}