import {
    Component
} from "../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-pages.scss";
import {WebsiteHeadComponent} from "../head/WebsiteHeadComponent";
import {WebsiteJoinComponent} from "../join/WebsiteJoinComponent";
import {WebsiteFooterComponent} from "../footer/WebsiteFooterComponent";
import {
    PageComponent
} from "../../../../../sedestral/router/pages/types/PageComponent";
import * as l from "../../../../panel/components/pages/page.scss";
import {WebsiteBlackJoinComponent} from "../black/join/WebsiteBlackJoinComponent";
export class WebsitePageComponent extends PageComponent {

    constructor() {
        super();
        //language=HTML
        this.template = `
        `;
    }

    contentComponent(): Component {
        return undefined;
    }

    init(): void {
        //language=HTML
        this.template = `
            <div class="${l.page}">
                <div class="${s.componentWebsitePage}">
                    <div class="${s.head}">
                        ${this.draw(new WebsiteHeadComponent())}
                    </div>
                    ${this.draw(this.contentComponent())}
                    <div class="${s.content}">
                        <div class="${s.contents}">
                            <div class="${s.container}">
                                ${this.draw(new WebsiteBlackJoinComponent())}
                            </div>
                        </div>
                    </div>
                    <div class="${s.footerContainer}">
                        ${this.draw(new WebsiteFooterComponent())}
                    </div>
                </div>
            </div>
        `;
    }

    onHash(): void {
    }
}