@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteProduct {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(5, 1fr);

  .brandContainer {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .imageContainer {
    grid-column-start: 3;
    grid-column-end: end;
    grid-row-start: 1;
    position: relative;
    grid-row-end: end;
  }

  .cardTexts {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    position: relative;
    grid-row-end: 6;
    min-width: 350px;
    padding: 38px 52px;
    box-shadow: 0 0 32.85px var(--black-alpha-middle);
    border: 1px solid var(--web-border-white);
    backdrop-filter: blur(30px);
    background: linear-gradient(0deg, #00001e, #000000cc);
    border-radius: 40px;

    .title {
      font-size: 42px;

      @include texts.font("bold");
    }

    .inbox {
      background: var(--grad-green);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .statistics {
      background: var(--grad-purple);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .livechat {
      background: var(--grad-blue-button);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .mail, .serviceclient {
      background: var(--grad-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .text {
      margin-top: 20px;
      font-size: 18px;
      @include texts.font("semi-bold");
    }

    .round {
      height: 120px;
      width: 120px;
      right: -60px;
      top: -60px;
      position: absolute;
      border-radius: 100%;
      background: var(--white);
      box-shadow: 0 0 32.85px var(--black-alpha-middle);
      display: flex;
      justify-content: center;
      align-items: center;

      &.prestashop {
        .icon {
          @include icons.ico("colored-prestashop");
        }
      }

      &.wix {
        .icon {
          @include icons.ico("black-wix");
        }
      }

      &.wordpress {
        .icon {
          @include icons.ico("colored-wordpress");
        }
      }

      &.shopify {
        .icon {
          @include icons.ico("colored-shopify");
        }
      }

      .icon {
        height: 70px;
        width: 70px;
      }
    }

    .promo {
      background: var(--grey-sur);
      border-radius: 12px;
      padding: 12px;
      margin-top: 15px;
      font-size: 14px;
    }

    .button {
      width: fit-content;
      margin-top: 30px;
      border-radius: 20px;
      border-top: 2px solid var(--web-border-blue);
      background: var(--web-grad-blue);
      font-size: 16px;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 32px;
      height: fit-content;
      cursor: pointer;

      &:hover {
        background: var(--web-blue-hover);

        .icon {
          transform: translateX(8px);
        }
      }

      .shadow {
        background: var(--web-blue);
        filter: blur(100px);
        width: 168px;
        height: 168px;
        position: absolute;
        pointer-events: none;
      }

      .content {
        display: flex;
        gap: 15px;
        position: relative;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;
          filter: var(--icon-white);
          transition: transform 0.1s ease-in-out;

          @include icons.ico("black-right-arrow");

          &.shopify {
            filter: unset !important;

            @include icons.ico("colored-shopify");
          }

          &.prestashop {
            filter: unset !important;

            @include icons.ico("colored-prestashop");
          }

          &.wix {
            @include icons.ico("black-wix");
          }

          &.wordpress {
            @include icons.ico("black-lineal-wordpress");
          }
        }
      }
    }
  }

  @media (max-width: 1050px) {
    grid-template-rows: repeat(4, 1fr);

    .cardTexts {
      .title {
        font-size: 40px;
      }

      .text {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 750px) {
    .brandContainer {
      grid-column-end: 4;
    }

    .imageContainer {
      grid-column-start: 4;
    }

    .cardTexts {
      min-width: 300px;
      padding: 25px 40px 50px 40px;

      .title {
        font-size: 35px;
      }

      .text {
        margin-top: 20px;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;

    .brandContainer {
      order: 1;
    }

    .imageContainer {
      order: 3;
      margin-top: 30px;
    }

    .cardTexts {
      margin-top: 20px;
      order: 2;
      min-width: 0;
      border: unset;
      padding: 0;
      background: transparent;
      box-shadow: none;

      .title {
        font-size: 33px;
      }

      .text {
        margin-top: 20px;
        font-size: 18px;
      }

      .round {
        width: 56px;
        height: 56px;
        right: 0px;

        .icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}