import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {WebsiteSliderLeftComponent} from "../../slider/left/WebsiteSliderLeftComponent";
import {Network} from "../../../../../../../network/Network";
import {WebsitePresentationDegradedComponent} from "../../presentation/degraded/WebsitePresentationDegradedComponent";
import {WebsiteColumnsThreeWhiteComponent} from "../../columns/three/white/WebsiteColumnsThreeWhiteComponent";
import {WebsiteColumnsDoubleComponent} from "../../columns/double/WebsiteColumnsDoubleComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteContentMailComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductComponent({
                            title: Resources.t("words.businessMails"),
                            text: Resources.t("words.manageAndCreateMails"),
                            productType: ProductType.MAILS,
                            url: `${Network.vendor}images/capture-mail-ask.webp`
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationDegradedComponent({
                            titles: [
                                {title: Resources.t("words.yourMails")},
                                {title: Resources.t("words.yourCompany")},
                                {title: Resources.t("words.yourDomainName")}
                            ],
                            image: "illus-domain.svg",
                            color: "multi",
                            size: "tall"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsThreeWhiteComponent({
                            title: Resources.t("words.bigTeams"),
                            columns: [{
                                name: Resources.t("words.createGroupMails"),
                                picture: "illus-members.svg",
                                state: "special"
                            }, {
                                name: Resources.t("words.supportMails"),
                                picture: "illus-domains.svg",
                                state: "normal"
                            }, {
                                name: Resources.t("words.collaborate"),
                                picture: "illus-editor.webp",
                                state: "normal"
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteSliderLeftComponent(Resources.t("words.fullControlMails"), [{
                            icon: "home",
                            title: Resources.t("words.overview"),
                            desc: Resources.t("words.spaceManagement"),
                            image: `${Network.vendor}images/` +"capture-overview.webp",
                            width: "561.6",
                            height: "315.9"
                        }, {
                            icon: "mail",
                            title: Resources.t("words.manageMails"),
                            desc: Resources.t("words.allocateMails"),
                            image: `${Network.vendor}images/` +"capture-emailAttribution.webp",
                            width: "561.6",
                            height: "315.9"
                        }, {
                            icon: "mailAlias",
                            title: Resources.t("words.aliasesRedirections"),
                            desc: Resources.t("words.setUpAliases"),
                            image: `${Network.vendor}images/` +"capture-aliasAttribution.webp",
                            width: "561.6",
                            height: "315.9"
                        }, {
                            icon: "domains",
                            title: Resources.t("words.domains"),
                            desc: Resources.t("words.addDomainName"),
                            image: `${Network.vendor}images/` +"capture-domainValidation.webp",
                            width: "561.6",
                            height: "315.9"
                        }]))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsDoubleComponent({
                            color: "red",
                            title: Resources.t("words.reliableMailsAddresses"),
                            columns: [{
                                icon: "error",
                                name: Resources.t("words.antiSpam"),
                                text: Resources.t("words.blockSpam"),
                            }, {
                                icon: "mailSpam",
                                name: Resources.t("words.antiVirus"),
                                text: Resources.t("words.securitySolution")
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.connectSocialNetworks"),
                            description: "",
                            image: `${Network.vendor}images/` + "capture-mail-connected.webp",
                            color: "black",
                            size: "tall"
                        }))}
                    </div>
                        <!--<div class="${s.container}">
                        $ {this.draw(new WebsiteMobileComponent())}
                    </div>-->
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("words.questions.mails")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}