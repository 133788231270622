import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-presentation-light.scss";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsitePresentationLightComponent extends Component {

    public illus: string;
    public size: string;
    public url: string;
    public direction: string;

    constructor(p: {
        title: string,
        subtitle: string,
        illus: string,
        size: string,
        url?: string,
        direction?: boolean
    }) {
        super();

        this.illus = p.illus;
        this.size = p.size;
        this.url = p.url;

        if (p.direction == true) {
            this.direction = ' style="flex-direction: row-reverse;"'
        } else {
            this.direction = ""
        }

        //language=HTML
        this.template = `
            <div class="${s.componentWebsitePresentationLight}" ${this.direction}>
                <div class="${s.left} ${s[this.size]}">
                    <div class="${s.article}">
                        <h3 class="${s.title}">
                            ${p.title}
                        </h3>
                        <p class="${s.subtitle}">
                            ${p.subtitle}
                        </p>
                        ${p.url ? `                   
                          <a class="${s.more}" href="${p.url}">
                           ${Resources.t("words.more")}
                            <i class="${s.arrow}"></i>
                        </a>` : ''}
                    </div>
                </div>
                <div class="${s.right} ${s[this.size]}">
                    <div class="${s.visual}">
                        <div class="${s.image} ${s[this.illus]}"></div>
                    </div>
                </div>
            </div>
        `;
    }
}