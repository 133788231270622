import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-black-words.scss";
import {Resources} from "../../../../../../resources/Resources";

export class WebsiteBlackWordsComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackWords}">
                <div class="${s.what}">
                    <div class="${s.ai}">
                        <div class="${s.blur}"></div>
                        <div class="${s.icon}"></div>
                    </div>
                    <div class="${s.text}">
                        ${Resources.t("words.sedestralAiIntro")}
                    </div>
                </div>
                <div class="${s.numbers}">
                    <div class="${s.number} ${s.purple}">
                        <div class="${s.integer}">
                            <div class="${s.blur}"></div>
                            <div class="${s.text}">
                                50+
                            </div>
                        </div>
                        <div class="${s.description}">
                            ${Resources.t("words.metricsUsed")}
                        </div>
                    </div>
                    <div class="${s.number} ${s.blue}">
                        <div class="${s.integer}">
                            <div class="${s.blur}"></div>
                            <div class="${s.text}">
                                350 000
                            </div>
                        </div>
                        <div class="${s.description}">
                            ${Resources.t("words.wordsAnalyzed")}
                        </div>
                        <div class="${s.line}"></div>
                    </div>
                </div>
                <div class="${s.separators}">
                    <div class="${s.separator}"></div>
                    <div class="${s.blur}"></div>
                </div>
                <div class="${s.explore}">
                    ${Resources.t("words.sedestralAiExploration")}
                </div>
                <div class="${s.charts}">
                    <div class="${s.entry}">
                        <div class="${s.title}">
                            ${Resources.t("words.sedestralAnalysis")}
                        </div>
                        <div class="${s.chart} ${s.blue}">
                            <div class="${s.bar}">
                                <div style="width: 100%;" class="${s.progression}"></div>
                            </div>
                            <div class="${s.value}">
                                350K
                            </div>
                        </div>
                    </div>
                    <div class="${s.entry}">
                        <div class="${s.title}">
                            ${Resources.t("words.firstFourHarryPotter")}
                        </div>
                        <div class="${s.chart} ${s.white}">
                            <div class="${s.bar}">
                                <div style="width: 90%;" class="${s.progression}"></div>
                            </div>
                            <div class="${s.value}">
                                327K
                            </div>
                        </div>
                    </div>
                    <div class="${s.entry}">
                        <div class="${s.title}">
                            ${Resources.t("words.nineStarWarsFilms")}
                        </div>
                        <div class="${s.chart} ${s.white}">
                            <div class="${s.bar}">
                                <div style="width: 70%;" class="${s.progression}"></div>
                            </div>
                            <div class="${s.value}">
                                267K
                            </div>
                        </div>
                    </div>
                    <div class="${s.entry}">
                        <div class="${s.title}">
                            ${Resources.t("words.lotrAndHobbit")}
                        </div>
                        <div class="${s.chart} ${s.white}">
                            <div class="${s.bar}">
                                <div style="width: 50%;" class="${s.progression}"></div>
                            </div>
                            <div class="${s.value}">
                                192K
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.els(s.progression).forEach(progressionComponent => {
            let width = parseInt(progressionComponent.getHTMLElement().style.width);

            this.el(s.charts).isBelowScroll((isVisible) => {
                if (isVisible) {
                    progressionComponent.translate({widthPercent: width, delay: 600});
                } else {
                    progressionComponent.translate({widthPercent: 0, delay: 600});
                }
            }, true);
        })
        super.commit();
    }

}
