import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {
    WebsiteContentBlogAnalysisComponent
} from "../components/content/pages/blog/WebsiteContentBlogAnalysisComponent";

export class WebsiteBlogAnalysisPage extends WebsitePageComponent {

    constructor() {
        super();
        this.updateMetas("analysis");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentBlogAnalysisComponent();
    }

    onHash(): void {

    }
}