@use "src/sedestral/sass/mixins/texts";

.componentWebsiteBlackText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include texts.font("bold");

  &.sizeNormal {
    font-size: 28px;

    .content {
      max-width: 840px;
    }
  }

  &.sizeBig {
    font-size: 36px;

    .content {
      max-width: 100%;
    }
  }

  &.colorBlue {
    .shadow {
      background: var(--web-blue);
    }
  }

  .shadow {
    width: 409px;
    height: 126px;
    filter: blur(100px);
    position: absolute;
    will-change: filter, transform;
  }

  .content {
    position: relative;
    padding: 15px 0;
  }

  @media screen and (max-width: 930px) {
    font-size: 28px !important;
  }
}