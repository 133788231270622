import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-black-indicators.scss";
import {IWebsiteBlackIndicators} from "./types/IWebsiteBlackIndicators";
import {WebsiteBlackUnionComponent} from "../union/WebsiteBlackUnionComponent";
import {Resources} from "../../../../../../resources/Resources";

export class WebsiteBlackIndicatorsComponent extends Component {
    constructor(indicators: IWebsiteBlackIndicators) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackPlatform}">
                <div class="${s.description}">
                    <div class="${s.content}">
                        ${indicators.description}
                    </div>
                </div>
                <div class="${s.subDescription}">
                    <div class="${s.blur}"></div>
                    <div class="${s.content}">
                        ${indicators.content}
                    </div>
                </div>
                ${this.draw(new WebsiteBlackUnionComponent({
                    ai: Resources.t("words.sedestralAI"),
                    cases: [
                        {
                            color: "blue",
                            name: Resources.t("words.siteAnalysis"),
                            description: Resources.t("words.siteAnalysisDescription")
                        }, {
                            color: "purple",
                            name: Resources.t("words.firstKeywords"),
                            description: Resources.t("words.firstKeywordsDescription")
                        }, {
                            color: "yellow",
                            name: Resources.t("words.googleSearches"),
                            description: Resources.t("words.googleSearchesDescription")
                        }
                    ]
                }))}
            </div>
        `;
    }
}
