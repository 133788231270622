import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-columns-double.scss";

export class WebsiteColumnsDoubleComponent extends Component {

    public columnsContainer: Component;
    public columns: { icon: string, name: string, text: string }[];

    public color: string;

    constructor(p: { color?: string, title: string, columns: { icon: string, name: string, text: string }[] }) {
        super();
        this.color = p.color;
        this.columns = p.columns;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteColumnsDouble}">
                <div class="${s.head}">
                    <h3 class="${s.title} ${this.color ? s[this.color] : ``}">
                        ${p.title}
                    </h3>
                </div>
                <div class="${s.columns}"></div>
            </div>
        `;
    }

    commit() {
        this.columnsContainer = this.el(s.columns);

        this.columns.forEach(column => {
            //language=HTML
            this.columnsContainer.append(`
                <div class="${s.column}">
                    <div class="${s.icon} ${s[column.icon]}"></div>
                    <h4 class="${s.name}">
                        ${column.name}
                    </h4>
                    <p class="${s.text}">
                        ${column.text}
                    </p>
                </div>
            `);
        });

        super.commit();
    }


}