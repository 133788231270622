import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {CustomerPopup} from "../../../../../../panel/components/components/customer/popup/types/CustomerPopup";
import {WebsiteBlackPlatformComponent} from "../../../black/platform/WebsiteBlackPlatformComponent";
import {WebsiteBlackFlowComponent} from "../../../black/flow/WebsiteBlackFlowComponent";
import {WebsiteBlackTextComponent} from "../../../black/text/WebsiteBlackTextComponent";
import {WebsiteBlackStepsComponent} from "../../../black/steps/WebsiteBlackStepsComponent";
import {WebsiteBlackPresentationComponent} from "../../../black/presentation/WebsiteBlackPresentationComponent";
import * as r from "../../../black/presentation/website-black-presentation.scss";
import {WebsiteBlackWordsComponent} from "../../../black/words/WebsiteBlackWordsComponent";
import {WebsiteBlackCasesComponent} from "../../../black/cases/WebsiteBlackCasesComponent";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteContentBlogPrestashopComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPresentationComponent({
                            startText: Resources.t("words.downloadModuleFree"),
                            center: true,
                            title: Resources.t("words.freeBlogModuleTitle"),
                            description: Resources.t("words.freeBlogModuleDescription"),
                            partnerIcon: "prestashop",
                            secondButton: true
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackStepsComponent({
                            title: Resources.t("words.stepsToCreatePublishArticle"),
                            icon: "seo",
                            contents: [
                                {
                                    name: Resources.t("words.writing"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-i.mp4"),
                                    duration: 16300
                                },
                                {
                                    name: Resources.t("words.managementInPrestaShop"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-j.mp4"),
                                    duration: 20000
                                },
                                {
                                    name: Resources.t("words.finalRender"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-k.mp4"),
                                    duration: 7000
                                },
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackTextComponent({
                            text: Resources.t("words.dailyEcommerceOptimization"),
                            size: "sizeNormal",
                            shadow: "colorBlue"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackCasesComponent({
                            title: Resources.t("words.moduleFeatures"),
                            cases: [
                                {
                                    name: Resources.t("words.fullInterface"),
                                    description: Resources.t("words.fullInterfaceDescription"),
                                    icon: "page"
                                }, {
                                    name: Resources.t("words.seoFriendly"),
                                    description: Resources.t("words.seoFriendlyDescription"),
                                    icon: "target"
                                }, {
                                    name: Resources.t("words.compatibleWithExistingBlog"),
                                    description: Resources.t("words.compatibleWithExistingBlogDescription"),
                                    icon: "box"
                                }
                            ]
                        }))}
                    </div>
                </div>
                <div class="${s.white}">
                    <div class="${s.content}">
                        <div class="${s.container}">
                            ${this.draw(new WebsiteBlackFlowComponent({
                                title: Resources.t("words.bestSeoAlly"),
                                bigTitle: Resources.t("words.completeSeoPlatform"),
                                image: NetworkVendor.image("blog-n.webp"),
                                texts: {
                                    canCollapse: true,
                                    entries: [
                                        {
                                            title: Resources.t("words.optimizeExistingSeo"),
                                            description: Resources.t("words.optimizeExistingSeoDescription"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        },
                                        {
                                            title: Resources.t("words.createEngagingContent"),
                                            description: Resources.t("words.createEngagingContentDescription"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        },
                                        {
                                            title: Resources.t("words.competitorAnalysis"),
                                            description: Resources.t("words.competitorAnalysisDescription"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        },
                                        {
                                            title: Resources.t("words.autopilot"),
                                            description: Resources.t("words.delegateContentCreationDescription"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        }
                                    ]
                                }
                            }))}
                        </div>
                    </div>
                </div>
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPlatformComponent({
                            title: Resources.t("words.ourPlatform"),
                            description: Resources.t("words.perfectForPrestashop"),
                            grid: {
                                lines: [{
                                    entries: [{
                                        title: Resources.t("words.automaticPilot"),
                                        description: Resources.t("words.automaticPilotDescription"),
                                        type: "icon",
                                        icon: "autopilot",
                                        size: "sizeMidColumn",
                                        color: "colorBlue",
                                        shadowPosition: "shadowMid"
                                    }, {
                                        title: Resources.t("words.workInAnyLanguage"),
                                        description: Resources.t("words.workInAnyLanguageDescription"),
                                        type: "icon",
                                        icon: "languages",
                                        size: "sizeMidColumn",
                                        color: "colorYellow",
                                        shadowPosition: "shadowMid"
                                    }]
                                }]
                            }
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackWordsComponent())}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        CustomerPopup.blog();
        super.commit();
    }
}



