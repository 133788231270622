import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "./schedule-content-statistics.scss";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {
    loadScript
} from "../../../../../../../sedestral/framework/utilities/Script";
import {
    LoaderLightComponent
} from "../../../../../../components/global/loader/light/LoaderLightComponent";
import {WebsiteIssuesComponent} from "../../issues/WebsiteIssuesComponent";

export class WebsiteContentScheduleComponent extends Component {

    public loaderLightComponent = new LoaderLightComponent();

    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentSchedule}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.schedule"),
                            description: "",
                            color: "white",
                            size: "intermediate"
                        }))}
                        <div style="margin-top:40px;" class="meetings-iframe-container" data-src="https://meetings.hubspot.com/alexis-avril?embed=true"></div>
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            theme: "black",
                            questions: Resources.t("words.questions.schedule")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        super.commit();
        loadScript("https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js", () => {});
    }
}