import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {WebsiteContentBlogComponent} from "../components/content/pages/blog/WebsiteContentBlogComponent";

export class WebsiteBlogPage extends WebsitePageComponent {

    constructor() {
        super();
        this.updateMetas("blog");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentBlogComponent();
    }

    onHash(): void {

    }
}