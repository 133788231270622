@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteColumnsGridWhite {
  display: flex;
  flex-direction: column;
  gap: 64px;

  .head {
    width: 100%;
    text-align: center;

    .title {
      padding: 0 15%;
      font-size: 44px;
      @include texts.font("bold");

      &.blue {
        background: var(--grad-blue);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .details {
    display: flex;
    flex-flow: wrap;
    gap: 56px;
    position: relative;
    flex-direction: row;

    .detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      width: calc(33.33% - 112px);
      border: 1px solid var(--grey-sur);
      border-radius: 14px;
      padding: 36px;
      text-align: center;
      background: linear-gradient(126deg, black, #ffffff14);

      .icon {
        width: 70px;
        height: 70px;
        filter: var(--icon-white);

        &.black-chart {
          @include icons.ico("black-chart");
        }

        &.black-lineal-time {
          @include icons.ico("black-lineal-time");
        }

        &.black-lineal-good {
          @include icons.ico("black-lineal-good");
        }

        &.black-export {
          @include icons.ico("black-export");
        }

        &.black-lineal-voice {
          @include icons.ico("black-lineal-voice");
        }

        &.black-lineal-words {
          @include icons.ico("black-lineal-words");
        }

        &.black-lineal-seo {
          @include icons.ico("black-lineal-seo");
        }

        &.black-lineal-code {
          @include icons.ico("black-lineal-code");
        }

        &.black-lineal-read {
          @include icons.ico("black-lineal-read");
        }

        &.black-lineal-keywords {
          @include icons.ico("black-lineal-tags");
        }

        &.black-lineal-domains {
          @include icons.ico("black-lineal-domains");
        }


        &.black-lineal-conversations {
          @include icons.ico("black-lineal-conversations");
        }

        &.black-lineal-planning {
          @include icons.ico("black-lineal-planning");
        }

        &.black-lineal-delay {
          @include icons.ico("black-lineal-delay");
        }

        &.black-lineal-rating {
          @include icons.ico("black-lineal-rating");
        }

        &.black-lineal-team {
          @include icons.ico("black-lineal-team");
        }
      }

      .title {
        font-size: 22px;
        @include texts.font("bold");
      }

      .subtitle {
        font-size: 14px;
        @include texts.font("semi-bold");
      }
    }
  }

  @media (max-width: 480px) {
    gap: 20px;

    .head {
      .title {
        padding: 0;
        font-size: 25px;
      }
    }

    .details {
      gap: 20px;
      flex-direction: column;

      .detail {
        gap: 20px;
        width: calc(100% - 40px);
        padding: 20px;

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}