import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {WebsiteContentInboxComponent} from "../components/content/pages/inbox/WebsiteContentInboxComponent";

export class WebsiteInboxPage extends WebsitePageComponent {
    constructor() {
        super();
        this.updateMetas("inbox");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentInboxComponent();
    }

    onHash(): void {

    }
}