@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteBlackDivide {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .what {
    display: flex;
    align-items: center;
    gap: 15px;

    .ai {
      width: 32px;
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-self: center;

      .blur {
        position: absolute;
        height: 100%;
        width: 100%;
        background: var(--web-blue);
        filter: blur(13px);
      }

      .icon {
        position: absolute;
        width: 27px;
        height: 27px;

        @include icons.ico("3d-ai");
      }
    }

    .text {
      font-size: 16px;
      text-transform: uppercase;
      color: var(--web-blue);
    }
  }

  .description {
    font-size: 28px;
    color: var(--white);

    @include texts.font("semi-bold");
  }

  .line {
    position: relative;
    width: 110px;
    display: flex;
    align-items: center;
    margin: 10px 0px;

    .shadow {
      height: 14px;
      width: 100%;
      position: absolute;
      filter: blur(10px);
      background: var(--white);
    }

    .content {
      height: 6px;
      width: 100%;
      background: var(--white);
      position: absolute;
      border-radius: 6px;
    }
  }

  .stepsText {
    font-size: 21px;
    color: var(--white);

    @include texts.font("semi-bold");
  }

  @media screen and (max-width: 930px) {
    .description {
      font-size: 25px;
    }
  }
}