import * as s from "./cases.scss";
import {Component} from "../../../../sedestral/framework/interface/component/Component";
import {ICaseEntry} from "./types/ICaseEntry";
import {LoaderLightComponent} from "../loader/light/LoaderLightComponent";

export class CasesComponent extends Component {

    public entries: ICaseEntry[];

    constructor(entries: ICaseEntry[]) {
        super();
        this.entries = entries;

        //language=HTML
        this.template = `
            <div class="${s.globalCase}"></div>`;
    }

    commit() {
        this.entries.forEach(entry => {
            //language=HTML
            let caseComponent = this.append(`
                <div class="${s.case} ${entry.mini ? s.mini : ``} ${s[entry.id]} ${entry.id}">
                    <div class="${s.icon}"></div>
                    <div class="${s.text}">
                        ${entry.name}
                    </div>
                </div>
            `);

            if (entry.description) {
                //language=HTML
                caseComponent.append(`
                    <div class="${s.description}">
                        ${entry.description}
                    </div>
                `);
            }

            if (entry.click) {
                caseComponent.onClick(() => {
                    caseComponent.clearAll();
                    caseComponent.render(new LoaderLightComponent());

                    entry.click();
                });
            }
        });

        super.commit();
    }

}