import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-black-join.scss";
import {Network} from "../../../../../../network/Network";
import {Resources} from "../../../../../../resources/Resources";

export class WebsiteBlackJoinComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackJoin}">
                <div class="${s.names}">
                    <div class="${s.title}">
                        ${Resources.t("words.organicTrafficWithinReach")}
                    </div>
                    <div class="${s.description}">
                        ${Resources.t("words.sedestralDescription")}
                    </div>
                    <a href="${Network.router.pages.getPanelUrl('signup')}" target="_blank" class="${s.start}">
                        <div class="${s.startShadow}"></div>
                        <div class="${s.content}">
                            ${Resources.t("words.beginFree")}
                            <div class="${s.icon}"></div>
                        </div>
                    </a>
                </div>
                <div class="${s.iconContainer}">
                    <div class="${s.icon}"></div>
                </div>
                <div class="${s.shadow}"></div>
            </div>
        `;
    }
}