@use "../../../../sedestral/sass/mixins/texts";
@use "../../../../sedestral/sass/mixins/positioning";
@use "../../../../sedestral/sass/mixins/effects";
@use "../../../../sedestral/sass/extends/boxs";
@use "../../../../sedestral/sass/icons/icons";

.globalSelectbox {
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  border: var(--grey-sur) 2px solid;
  overflow: hidden;
  width: calc(100% - 24px);
  position: relative;
  height: calc(100% - 4px);
  color: var(--black);
  display: flex;
  padding: 0px 10px;
  align-items: center;

  @include effects.hover-alpha;
  @include texts.font("semi-bold");

  &:hover {
    border-color: transparent !important;
  }

  &.disabled {
    pointer-events: none;
    background: var(--grey-sur);
  }

  .selectIcon {
    height: 12px;
    width: 12px;
    pointer-events: none;
    margin-left: auto;
    filter: var(--icon-black);

    @include icons.ico("black-bottom-bold");
  }

  .selectButton {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px !important;
  }

}