import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "./website-content-academy.scss";
import {WebsiteBlogArticleComponent} from "../../blog/WebsiteBlogArticleComponent";

export class WebsiteContentAcademyComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentPricing}">
                <div style="gap:20px;" class="${s.content}">
                    <div class="${r.academy}">
                        Académie
                    </div>
                    <h2 class="${r.title}">
                        Découvrez nos cours et conseils pratiques pour optimiser efficacement votre SEO
                    </h2>
                </div>
                <div class="${s.white}">
                    <div class="${s.content}">
                        <div class="${s.container}">
                            <div style="display: flex;gap:30px;width: 100%;margin-bottom: 35px;">
                                ${this.draw(new WebsiteBlogArticleComponent({
                                    title: "Maîtriser le SEO : stratégies avancées pour optimiser votre visibilité en ligne",
                                    url: "URL",
                                    snippet: "Apprenez à optimiser votre site web avec des techniques SEO avancées : analyse sémantique, clusters de mots-clés et suivi de performance.",
                                    keywords: [],
                                    time: Date.now(),
                                    lastEditTime: Date.now(),
                                    readingSeconds: 300
                                }))}
                                ${this.draw(new WebsiteBlogArticleComponent({
                                    title: "Maîtriser le SEO : stratégies avancées pour optimiser votre visibilité en ligne",
                                    url: "URL",
                                    snippet: "Apprenez à optimiser votre site web avec des techniques SEO avancées : analyse sémantique, clusters de mots-clés et suivi de performance.",
                                    keywords: [],
                                    time: Date.now(),
                                    lastEditTime: Date.now(),
                                    readingSeconds: 300
                                }))}
                            </div>
                            <div style="display: flex;gap:30px;width: 100%;">
                                ${this.draw(new WebsiteBlogArticleComponent({
                                    title: "Maîtriser le SEO : stratégies avancées pour optimiser votre visibilité en ligne",
                                    url: "URL",
                                    snippet: "Apprenez à optimiser votre site web avec des techniques SEO avancées : analyse sémantique, clusters de mots-clés et suivi de performance.",
                                    keywords: [],
                                    time: Date.now(),
                                    lastEditTime: Date.now(),
                                    readingSeconds: 300
                                }))}
                                ${this.draw(new WebsiteBlogArticleComponent({
                                    title: "Maîtriser le SEO : stratégies avancées pour optimiser votre visibilité en ligne",
                                    url: "URL",
                                    snippet: "Apprenez à optimiser votre site web avec des techniques SEO avancées : analyse sémantique, clusters de mots-clés et suivi de performance.",
                                    keywords: [],
                                    time: Date.now(),
                                    lastEditTime: Date.now(),
                                    readingSeconds: 300
                                }))}
                                ${this.draw(new WebsiteBlogArticleComponent({
                                    title: "Maîtriser le SEO : stratégies avancées pour optimiser votre visibilité en ligne",
                                    url: "URL",
                                    snippet: "Apprenez à optimiser votre site web avec des techniques SEO avancées : analyse sémantique, clusters de mots-clés et suivi de performance.",
                                    keywords: [],
                                    time: Date.now(),
                                    lastEditTime: Date.now(),
                                    readingSeconds: 300
                                }))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}