import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {WebsiteContentCgvComponent} from "../components/content/pages/cgv/WebsiteContentCgvComponent";
import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";

export class WebsiteCgvPage extends WebsitePageComponent {
    constructor() {
        super();
        this.updateMetas("cgv");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentCgvComponent();
    }

    onHash(): void {

    }
}