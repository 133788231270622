import {
    VisualModalComponent
} from "../../../../../../components/visual/modal/VisualModalComponent";
import {SiteCreatorComponent} from "../SiteCreatorComponent";

export class SiteCreatorModalComponent extends VisualModalComponent {
    constructor() {
        super();
        this.name = "";
        this.autoResize = true;
        this.modalTemplate = this.draw(new SiteCreatorComponent());
    }

    commit() {
        super.commit();
    }
}