import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {WebsiteContentBlogWritingComponent} from "../components/content/pages/blog/WebsiteContentBlogWritingComponent";
import {
    WebsiteContentHelpCenterComponent
} from "../components/content/pages/helpcenter/WebsiteContentHelpCenterComponent";

export class WebsiteHelpcenterPage extends WebsitePageComponent {
    constructor() {
        super();
        this.updateMetas("helpcenter");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentHelpCenterComponent();
    }

    onHash(): void {

    }
}