@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsitePresentationVertical {
  .head {
    width: 100%;
    text-align: center;

    .title {
      padding: 0 15%;
      font-size: 44px;

      @include texts.font("bold");

      &.blue {
        background: var(--grad-blue);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.red {
        background: var(--grad-red);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.black {
        background: var(--grad-black);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.multi {
        background: var(--grad-multi);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.purple {
        background: var(--grad-purple);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.green {
        background: var(--grad-green);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.white {
        color: var(--white);
      }

    }

    .description {
      padding: 0 15%;
      margin-top: 30px;
      font-size: 18px;

      @include texts.font("semi-bold");
    }

  }

  .imageContainer {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .image {
      visibility: visible;

      &.tall {
        width: 100%;
        height: 100%;
      }

      &.medium {
        width: 60%;
        height: 100%;
      }

      &.intermediate {
        width: 80%;
        height: 100%;
      }

      &.shadow {
        box-shadow: 0 0 35px var(--black-alpha-middle);
      }

      &.border {
        border-radius: 20px;
      }
    }
  }

  @media (max-width: 750px) {
    .head {
      text-align: center;

      .title {
        padding: 0;
        font-size: 30px;
      }

      .description {
        padding: 0;
        margin-top: 24px;
        font-size: 18px;
      }
    }
  }

  @media (max-width: 480px) {
    .head {
      .title {
        font-size: 25px;
      }

      .description {
        padding: 0;
        margin-top: 24px;
        font-size: 16px;
      }
    }

    .imageContainer {
      margin-top: 25px;

      .image {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}