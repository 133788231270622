export enum OfferProductSolutionType {
    ACCOUNT = 0,
    LIVECHAT = 1,
    MAILS = 2,
    IA = 3,
    SITE_CHANNEL = 4,
    COMMUNITY = 5,
    AI_CONTENT = 6,
    AUTOPILOT = 7,
    WRITING_STYLE = 8,
    SEO_RANKING = 9,
    SEO_CLUSTER = 10,
    SEO_AUDIT = 11,
    OPTIMIZATION_CONTENT= 12,
    BRIEF_CONTENT = 13
}