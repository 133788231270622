@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsitePresentationDegraded {
  .head {
    width: 100%;
    text-align: center;

    .title {
      padding: 0 15%;
      font-size: 44px;
      @include texts.font("bold");

      &.medium {
        font-size: 34px;
        @include texts.font("bold");
      }

      &.small {
        font-size: 24px;
        @include texts.font("bold");
      }

    }

    &.blue > .title {
      background: var(--grad-blue);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.red > .title {
      background: var(--grad-red);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.black > .title {
      background: var(--grad-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.multi > .title {
      background: var(--grad-multi);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.purple > .title {
      background: var(--grad-purple);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

  }

  .imageContainer {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .image {
      visibility: visible;

      &.tall {
        width: 100%;
        height: 100%;
      }

      &.medium {
        width: 60%;
        height: 100%;
      }
    }
  }

  @media (max-width: 480px) {
    .head {
      .title {
        padding: 0;
        font-size: 23px;
        @include texts.font("bold");

        &.medium {
          font-size: 23px;
          @include texts.font("bold");
        }

        &.small {
          font-size: 23px;
          @include texts.font("bold");
        }
      }
    }

    .imageContainer {
      margin-top: 25px;
    }
  }
}