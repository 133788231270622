@use "src/sedestral/sass/mixins/texts";

.componentWebsiteBlackPlatform {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .title {
    font-size: 16px;
    color: var(--web-purple-text);
    text-transform: uppercase
  }

  .description {
    font-size: 28px;
    position: relative;
    max-width: 751px;

    @include texts.font("semi-bold");

    span {
      color: var(--web-text-white-alpha);
    }

    .shadow {
      width: 182px;
      height: 101px;
      background: var(--web-purple);
      filter: blur(95px);
      position: absolute;
      top: 0;
      left: -70px;
    }

    .content{
      position: relative;
    }
  }

  .grid {
    margin-top: 45px;
  }
}