import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-black-divide.scss";
import {IWebsiteBlackDivide} from "./types/IWebsiteBlackDivide";
import {WebsiteBlackGridComponent} from "../grid/WebsiteBlackGridComponent";

export class WebsiteBlackDivideComponent extends Component {
    constructor(divide: IWebsiteBlackDivide) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackDivide}">
                <div class="${s.what}">
                    <div class="${s.ai}">
                        <div class="${s.blur}"></div>
                        <div class="${s.icon}"></div>
                    </div>
                    <div class="${s.text}">
                        Que fait Sedestral AI ?
                    </div>
                </div>
                <div class="${s.description}">
                    Sedestral AI s'occupe d'analyser vos concurrents, de mener les recherches et d'effectuer toutes les
                    tâches fastidieuses pour vous.
                </div>
                <div class="${s.line}">
                    <div class="${s.shadow}"></div>
                    <div class="${s.content}"></div>
                </div>
                <div class="${s.stepsText}">
                    Les tâches se divisent en plusieurs étapes
                </div>
                <div>
                    ${this.draw(new WebsiteBlackGridComponent(divide.grid))}
                </div>
            </div>
        `;
    }
}