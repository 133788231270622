import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./site-none.scss";
import {
    LoaderLightComponent
} from "../../../../../components/global/loader/light/LoaderLightComponent";
import {SiteCreatorModalComponent} from "../creator/modal/SiteCreatorModalComponent";
import {SiteService} from "../../../../../../services/site/SiteService";
import {SitesModalComponent} from "../modal/SitesModalComponent";
import {Resources} from "../../../../../../resources/Resources";
import {AvatarComponent} from "../../avatar/AvatarComponent";
import {AccountSessionService} from "../../../../../../services/account/session/AccountSessionService";

export class SiteNoneComponent extends Component {
    private sitePickerContainer: Component;
    private createContainer: Component;

    constructor(permissions?: boolean) {
        super();

        //language=HTML
        this.template = `
            <div siteNone class="${s.componentSiteNone}">
                <div siteNoneHead class="${s.head}">
                    <div class="${s.text}">
                        ${Resources.t("words.sedestral")}
                    </div>
                </div>
                <div class="${s.content}">
                    <div class="${s.container}">
                        <div class="${s.illustration}"></div>
                        <div class="${s.title}">
                            ${permissions ? Resources.t("words.noPermission") : Resources.t("words.noActiveWorkspace")}
                        </div>
                        <div class="${s.description}">
                            ${Resources.t("words.mustHaveWorkspace")}
                        </div>
                        <div class="${s.box}">
                            <div class="${s.sitePicker}">
                                ${this.draw(new LoaderLightComponent())}
                            </div>
                            <div class="${s.separator}"></div>
                            <div class="${s.create}">
                                ${Resources.t("words.addWorkspace")}
                            </div>
                            <div class="${s.logout}">
                                ${Resources.t("words.deconnexion")}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="${s.aquarius}"></div>
            </div>
        `;
    }

    commit() {
        this.sitePickerContainer = this.el(s.sitePicker);
        this.createContainer = this.el(s.create);

        this.createContainer.onClick(() => {
            let creator = new SiteCreatorModalComponent();
            this.render(creator);
        });

        let logoutButton = this.el(s.logout);
        let onLogout = false;
        logoutButton.onClick(async (e) => {
            e.stopPropagation();
            e.stopImmediatePropagation();
            if (!onLogout) {
                onLogout = true;
                logoutButton.save();
                logoutButton.clear();
                logoutButton.render(new LoaderLightComponent());
                await AccountSessionService.logout(this);
            }
        }, true);

        this.renderSitePicker();
        super.commit();
    }

    async renderSitePicker() {
        let sites = await SiteService.findMind(this);
        this.sitePickerContainer.clearAll();

        if (!sites || sites.length == 0) {
            this.sitePickerContainer.setHtml(`<span>${Resources.t("words.noAffiliatedWorkspace")}</span>`);
        } else {
            //language=HTML
            this.sitePickerContainer.append(`
                <div class="${s.avatarContainer}">
                    ${this.sitePickerContainer.draw(new AvatarComponent(sites[0].avatar))}
                </div>
                <div class="${s.name}">
                    ${this.sitePickerContainer.reactive(() => sites[0].name)}
                </div>
                <div class="${s.arrow}"></div>
            `);

            this.sitePickerContainer.onClick(() => {
                let modal = new SitesModalComponent(sites);
                modal.sitesComponent.onPick = (site) => {
                    modal.close();
                    this.sitePickerContainer.clearAll();
                    this.sitePickerContainer.render(new LoaderLightComponent());
                    SiteService.select(site.id, this);
                };
                this.render(modal);
            });
        }
    }

}