import "./../../sedestral/sass/sedestral.scss";
import "../components/global/loader/background/loader-background.scss";
import {Resources} from "../../resources/Resources";
import {Network} from "../../network/Network";
import {WebsiteRouter} from "./components/WebsiteRouter";
import "./Website.scss";
import {ProductsLanguages} from "../ProductsLanguages";

export class Website {
    public static router: WebsiteRouter;

    public static async init(): Promise<void> {
        this.router = new WebsiteRouter(ProductsLanguages.website);

        await Network.start(this.router);
        await Resources.init([this.router.language()], this.router.languages);
        try {
            await Promise.all([
                await Resources.loadI18n("website"),
                await Resources.loadCountries(),
                await Resources.loadWorldLanguages()
            ]);
        } catch (e) {
        }

        await this.router.init({defaultLanguage: Resources.language, languages: Resources.languages});
    }
}