@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteIssues {
  border: 1px solid var(--grey-sur);
  border-radius: 14px;
  padding: 32px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 37px;

  &.black {
    border: unset;
    padding: 32px 0px;

    .issue {
      border: 1px solid var(--grey-sur);
      background: linear-gradient(126deg, #000, #ffffff14) !important;
      border-radius: 22px !important;

      .icon {
        filter: var(--icon-white);
      }
    }
  }

  .title {
    font-size: 32px;
    @include texts.font("bold");
  }

  .issues {
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 100%;

    .issue {
      width: calc(100% - 55px);
      background: var(--white);
      box-shadow: 0 0 20px var(--black-alpha-middle);
      border-radius: 8px;
      padding: 25px 30px 25px 25px;
      display: flex;
      flex-direction: column;
      gap: 34px;

      .top {
        width: 100%;
        display: flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
          .more {
            background: var(--grey-sur);
          }
        }

        .name {
          font-size: 20px;
          @include texts.font("semi-bold");
        }

        .more {
          padding: 5px;
          border-radius: 50%;
          height: 16px;

          .icon {
            width: 16px;
            height: 16px;
            @include icons.ico("black-plus");

            &.open {
              animation: rotationLeft 0.5s forwards;
            }

            @keyframes rotationLeft {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(45deg);
              }
            }

            &.close {
              animation: rotationRight 0.5s forwards;
            }

            @keyframes rotationRight {
              0% {
                transform: rotate(45deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }
          }
        }
      }

      .bottom {
        width: 100%;

        ul {
          margin-top: 10px;
          font-size: 14px;
        }

        li {
          margin: 0;
          list-style-type: circle;
        }

        strong {
          font-weight: normal;
        }

        .description {
          font-size: 14px;
          @include texts.font("medium");

          a {
            color: var(--blue);
            text-decoration: none;
          }

        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  @media (max-width: 480px) {
    padding: 25px 16px;
    gap: 25px;

    .title {
      text-align: center;
      font-size: 25px;
    }

    .issues {
      gap: 16px;

      .issue {
        gap: 16px;

        .top {
          .name {
            font-size: 16px;
          }
        }

        .bottom {
          .description {
            font-size: 13px;
          }
        }
      }
    }
  }
}