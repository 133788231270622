import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {WebsiteContentMailComponent} from "../components/content/pages/mail/WebsiteContentMailComponent";

export class WebsiteMailPage extends WebsitePageComponent {

    constructor() {
        super();
        this.updateMetas("mails");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentMailComponent();
    }

    onHash(): void {

    }
}