import * as s from "./site-creator-join.scss";
import {
    StepsComponent
} from "../../../../../../components/visual/steps/StepsComponent";
import {
    ButtonsSaveComponent
} from "../../../../../../components/global/buttons/save/ButtonsSaveComponent";
import {InvitationService} from "../../../../../../../services/invitation/InvitationService";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";

export class SiteCreatorJoinComponent extends StepsComponent {

    private buttons: ButtonsSaveComponent;

    constructor() {
        super();
        this.buttons = new ButtonsSaveComponent();

        //language=HTML
        this.template = `
            <div class="${s.componentSiteCreatorJoin}">
                <input class="${s.input}" placeholder="${Resources.t("words.invitationLink")}"/>
                <div class="${s.buttons}">
                    ${this.draw(this.buttons)}
                </div>
            </div>
        `;
    }

    commit() {
        this.buttons.onSave = async () => {
            let link = this.el(s.input).getValue();
            if (!link.includes("invitation/i/")) {
                return this.error();
            }

            //http://localhost:1111/#/invitation/i/691a0513bc194cd2945e9716f24a44a9a21881e43a3b4916b50c47e6fa0c91e53893801b8b5a49f4a5bc7be953d5b8cx
            let invitationId = link.split("invitation/i/")[1];
            if (invitationId.length < 1) {
                return this.error();
            }

            this.buttons.setLoading();
            let invitation = await InvitationService.findById(invitationId, this);
            if (invitation == undefined) {
                return this.error();
            }

            await Network.router.pages.redirect("/invitation/i/" + invitationId);
        }

        super.commit();
    }

    error() {
        Network.router.static.components.notifications.notify(Resources.t("words.unableToContinue"), Resources.t("words.invalidInvitationLink"), 8000);
        this.buttons.remLoading();
    }

}