import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "./website-content-customerService.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Network} from "../../../../../../../network/Network";
import {WebsiteProductGridComponent} from "../../product/grid/WebsiteProductGridComponent";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteContentCustomerServiceComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentClientService}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductComponent({
                            title: Resources.t("words.allInOne"),
                            text: Resources.t("words.fastSupport"),
                            productType: ProductType.CLIENT_SERVICE,
                            url: `${Network.vendor}images/capture-inbox.webp`
                        }))}
                    </div>
                    <div class="${r.see}">
                        <h3 class="${r.subtitle}">
                            ${Resources.t("words.multiChannel")}
                        </h3>
                        <h4 class="${r.title}">
                            ${Resources.t("words.letSee")}

                        </h4>
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductGridComponent({
                            components: [{
                                title: Resources.t("words.products.frLivechat"),
                                text: Resources.t("words.livechatCustomize"),
                                textButton: Resources.t("words.desktop"),
                                image: "livechat-a.webp",
                                url: "livechat"
                            }, {
                                title: Resources.t("words.products.mails"),
                                text: `<span>1.</span> ${Resources.t("words.mailsConnect")}<br/><br/><span>2.</span> ${Resources.t("words.mailsCreate")}<br/><br/><span>3.</span> ${Resources.t("words.mailsAssigned")}<br/><br/><span>4.</span> ${Resources.t("words.mailsWork")}`,
                                textButton: Resources.t("words.mailsServe"),
                                image: "illus-sc-mails.webp",
                                url: "mail"
                            }, {
                                title: Resources.t("words.products.socialNetworks"),
                                text: Resources.t("words.linkToSN"),
                                textButton: Resources.t("words.SNServe"),
                                image: "illus-sc-social.svg",
                                url: "inbox"
                            }, {
                                title: Resources.t("words.products.helpCenter"),
                                text: Resources.t("words.helpcenterCustomize"),
                                image: "illus-sc-support.svg"
                            }, {
                                title: Resources.t("words.products.chatBot"),
                                text: Resources.t("words.trainIA"),
                                image: "illus-sc-bots.svg"
                            }]
                        }))}
                    </div>
                    <div class="${r.see}">
                        <h3 class="${r.title}">
                            ${Resources.t("words.customerService")}
                        </h3>
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("words.questions.customerService")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}