import {
    Component
} from "../../../../../sedestral/framework/interface/component/Component";
import {SiteComponent} from "./SiteComponent";
import * as s from "./sites.scss";
import {ISiteModel} from "../../../../../models/site/ISiteModel";
import {SiteService} from "../../../../../services/site/SiteService";
import {
    LoaderLightComponent
} from "../../../../components/global/loader/light/LoaderLightComponent";

export class SitesComponent extends Component {

    private sites: ISiteModel[];
    private checkedIds: string[];

    constructor(sites?: ISiteModel[], checkedIds?: string[]) {
        super();
        this.sites = sites;
        this.checkedIds = checkedIds;

        //language=HTML
        this.template = `
            <div class="${s.componentSites}"></div>
        `;
    }

    async commit() {
        if (this.sites == undefined) {
            this.render(new LoaderLightComponent());
            this.sites = await SiteService.findMind(this);
            this.clearAll();
        }

        this.sites.forEach(site => {
            let component = new SiteComponent(site, this.checkedIds != undefined && this.checkedIds.includes(site.id));
            this.render(component);
            component.onClick(() => this.onPick(site));
        });

        this.scrollable();
        super.commit();
    }

    /**
     * override
     */

    onPick(site: ISiteModel) {

    }
}