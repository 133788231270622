import {
    Component
} from "../../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-product-grid-right.scss";
import {Network} from "../../../../../../../../network/Network";
import {Resources} from "../../../../../../../../resources/Resources";

export class WebsiteProductGridRightComponent extends Component {

    public components: { title: string, text: string, textButton?: string, image: string, url?: string }[];
    public componentContainer: Component;

    constructor(p: {
        components: { title: string, text: string, textButton?: string, image: string, url?: string }[]
    }) {
        super();

        this.components = p.components;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteProductGrid}"></div>
        `;
    }

    commit() {
        this.componentContainer = this.el(s.componentWebsiteProductGrid);
        this.components.forEach(components => {
            if (components.url && components.url !== "" && components.textButton !== "" && components.textButton) {
                //language=HTML
                this.componentContainer.append(`
                    <div class="${s.component}">
                        <div class="${s.top}">
                            <div class="${s.icon}"></div>
                            <h3 class="${s.title}">
                                ${components.title}
                            </h3>
                        </div>
                        <div class="${s.bottom}">
                            <div class="${s.left}">
                                <div class="${s.texts}">
                                    <p class="${s.text}">${components.text}</p>
                                    <a href="/${components.url}">
                                        <div class="${s.button}">
                                            <p class="${s.textButton}">${components.textButton}</p>
                                            <i class="${s.iconButton}"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="${s.right}">
                                <img src="${Network.vendor}images/${components.image}"
                                     alt="${Resources.t("words.illustrationCategory")} ${components.title}."/>
                            </div>
                        </div>
                    </div>
                `);
            } else {
                //language=HTML
                this.componentContainer.append(`
                    <div class="${s.component}">
                        <div class="${s.top}">
                            <div class="${s.icon}"></div>
                            <h3 class="${s.title}">
                                ${components.title}
                            </h3>
                        </div>
                        <div class="${s.bottom}">
                            <div class="${s.left}">
                                <div class="${s.texts}">
                                    <p class="${s.text}">${components.text}</p>
                                </div>
                            </div>
                            <div class="${s.right}">
                                <img src="${Network.vendor}images/${components.image}"
                                     alt="${Resources.t("words.illustrationCategory")} ${components.title}."/>
                            </div>
                        </div>
                    </div>
                `);
            }
        });
        super.commit();
    }
}