import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-black-cases.scss";
import {IWebsiteBlackCases} from "./types/IWebsiteBlackCases";

export class WebsiteBlackCasesComponent extends Component {

    public cases: IWebsiteBlackCases;
    public casesContainer: Component;

    constructor(cases: IWebsiteBlackCases) {
        super();
        this.cases = cases;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackCases}">
                <div class="${s.title}">${cases.title}</div>
                <div class="${s.cases}"></div>
            </div>
        `;
    }

    commit() {
        this.casesContainer = this.el(s.cases);
        this.cases.cases.forEach(value => {
            //language=HTML
            this.casesContainer.append(`
                <div class="${s.case}">
                    <div class="${s.name}">${value.name}</div>
                    <div class="${s.description}">
                        ${value.description}
                    </div>
                    <div class="${s.iconContainer}">
                        <div class="${s.icon} ${s[value.icon]}"></div>
                    </div>
                    <div class="${s.shadow}"></div>
                </div>
            `);
        });

        super.commit();
    }
}