import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-presentation-vertical.scss";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteVideoComponent} from "../../video/WebsiteVideoComponent";

export class WebsitePresentationVerticalComponent extends Component {

    public title: string;
    public color: string;
    public size: string;
    public shadow: string;
    public border: string;

    public image: string;
    public imageContainer: Component;
    public imageComponent: Component;

    constructor(p: {
        title: string,
        description?: string,
        image?: string,
        imageComponent?: Component,
        color: string,
        size: string,
        shadow?: string,
        border?: string
    }) {
        super();

        this.imageComponent = p.imageComponent;
        this.title = p.title;
        this.image = p.image;
        this.color = p.color;
        this.size = p.size;
        this.shadow = p.shadow;
        this.border = p.border;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsitePresentationVertical}">
                <div class="${s.head}">
                    <h2 class="${s.title} ${s[this.color]}">
                        ${p.title}
                    </h2>
                    ${p.description ? `<p class="${s.description}">${p.description}</p>` : ``}
                </div>
            </div>
        `;
    }

    commit() {
        if (this.image || this.imageComponent) {
            this.imageContainer = this.append(`<div class="${s.imageContainer}"></div>`);

            if (this.imageComponent)
                this.imageContainer.render(this.imageComponent);

            if (this.image) {
                if (this.image.endsWith(".mp4")) {
                    this.imageContainer.render(new WebsiteVideoComponent(this.image))
                } else {
                    //language=HTM
                    this.imageContainer.append(`
                        <img class="${s.image} ${s[this.size]} ${s[this.shadow]} ${s[this.border]}"
                             src="${this.image}"
                             alt="${Resources.t("words.sedestralInterfacePageRepresenting")} : ${this.title}."/>`);
                }
            }
        }


        super.commit();
    }

}