import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-black-union.scss";
import {IWebsiteBlackUnion} from "./types/IWebsiteBlackUnion";

export class WebsiteBlackUnionComponent extends Component {

    public union: IWebsiteBlackUnion;

    public casesContainer: Component;

    constructor(union: IWebsiteBlackUnion) {
        super();
        this.union = union;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackUnion}">
                <div class="${s.rect}">
                    <div class="${s.pastille}">
                        <div class="${s.iconContainer}">
                            <div class="${s.shadow}"></div>
                            <div class="${s.icon}"></div>
                        </div>
                        ${union.ai}
                    </div>
                </div>
                <div class="${s.union}"></div>
                <div class="${s.cases}"></div>
            </div>
        `;
    }

    commit() {
        this.casesContainer = this.el(s.cases);
        this.union.cases.forEach(value => {
            //language=HTML
            this.casesContainer.append(`
                <div class="${s.case} ${s[value.color]}">
                    <div class="${s.content}">
                        <div class="${s.names}">
                            <div class="${s.blur}"></div>
                            <div class="${s.name}">
                                ${value.name}
                            </div>
                        </div>
                        <div class="${s.description}">
                            ${value.description}
                        </div>
                    </div>
                </div>
            `);
        });

        super.commit();
    }
}