import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-head-submenu-content.scss";

export abstract class WebsiteHeadSubmenuContentComponent extends Component {
    openColumn(column: Component) {
        column.el(s.cases).displayFlex();
        column.addClass(s.open);
    }

    closeColumn(column: Component) {
        column.el(s.cases).displayHide();
        column.removeClass(s.open);
    }
}