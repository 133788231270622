import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {WebsiteContentBlogWixComponent} from "../components/content/pages/blog/WebsiteContentBlogWixComponent";
import {WebsiteContentBlogShopifyComponent} from "../components/content/pages/blog/WebsiteContentBlogShopifyComponent";

export class WebsiteBlogShopifyPage extends WebsitePageComponent {

    constructor() {
        super();
        this.updateMetas("shopify");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentBlogShopifyComponent();
    }

    onHash(): void {

    }
}