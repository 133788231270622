@use "src/sedestral/sass/mixins/texts";

.componentWebsiteTable {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: 40px;
    margin-bottom: 0;
    text-align: center;
  }

  h2 {
    @include texts.font("bold");
  }

  .subtitle {
    text-align: center;
  }

  .clause, .clauseTitle, .clauseText {
    padding: 20px 25px;
  }

  .clauses {
    visibility: visible;
  }

  .clause {
    margin-top: 30px;
    border-radius: 12px;
    border: 1px solid var(--grey-sur);
  }

  @media (max-width: 480px) {
    .title {
      margin-bottom: 14px;
      font-size: 35px;
    }
  }

}