import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-table.scss";

export class WebsiteTableComponent extends Component {

    public clauses: { header: string, explain: string }[];
    public clausesContainer: Component;

    constructor(p: { clauses: { header: string, explain: string }[], title: string, subtitle: string }) {
        super();

        this.clauses = p.clauses;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteTable}">
                <h2 class="${s.title}">
                    ${p.title}
                </h2>
                <h4 class="${s.subtitle}">
                    ${p.subtitle}
                </h4>
                <div class="${s.clauses}">

                </div>
            </div>
        `;
    }

    commit() {
        this.clausesContainer = this.el(s.clauses);
        this.clauses.forEach(clause => {
            if (clause.explain != "") {
                //language=HTML
                this.clausesContainer.append(`
                    <div class="${s.clause}">
                        <h3 class="${s.clauseTitle}">
                            ${clause.header}
                        </h3>
                        <p class="${s.clauseText}">
                            ${clause.explain}
                        </p>
                    </div>
                `);
            } else {
                //language=HTML
                this.clausesContainer.append(`
                    <div class="${s.clause}">
                        <h3 class="${s.clauseTitle}">
                            ${clause.header}
                        </h3>
                    </div>
                `);
            }
        });
        super.commit();
    }
}