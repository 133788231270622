@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteBlackGrid {
  display: flex;
  gap: 33px;
  flex-direction: column;

  .line {
    display: flex;
    gap: 33px;

    .entry {
      border-radius: 22px;
      border: 1px solid var(--web-border-white);
      background: var(--web-brackground-grid-green);
      padding: 25px;
      width: 30%;
      position: relative;
      display: flex;
      flex-direction: column;

      .names {
        display: flex;
        flex-direction: column;
        gap: 25px;
        position: relative;
        z-index: 1;

        .title {
          font-size: 21px;
          display: flex;
          align-items: center;
          gap: 10px;

          @include texts.font("semi-bold");

          &.hasStep {
            display: block;
          }

          .step {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 15px;
            width: 6px;
            margin-right: 10px;

            .stepShadow {
              height: 1px;
              box-shadow: 0 0 20px 8px var(--white);
              position: absolute;
            }

            .text {
              position: absolute;

              @include texts.font("semi-bold");
            }
          }

          .ai {
            display: flex;
            align-items: center;
            justify-content: center;
            top: -2px;
            position: relative;

            .aiShadow {
              width: 32px;
              height: 32px;
              background: var(--web-blue);
              filter: blur(13px);
              position: absolute;
            }

            .aiIcon {
              height: 27px;
              width: 27px;
              position: relative;

              @include icons.ico("3d-ai");
            }
          }
        }

        .description {
          font-size: 13.5px;
        }
      }

      .iconContainer {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .icon {
          height: 130px;
          width: 130px;

          &.optimize {
            @include icons.ico("illus-black-optimize");
          }

          &.ready {
            @include icons.ico("illus-black-ready");
          }

          &.unique {
            @include icons.ico("illus-black-unique");
          }

          &.picture {
            @include icons.ico("illus-black-picture");
          }

          &.seo {
            @include icons.ico("illus-black-seo");
          }

          &.write {
            @include icons.ico("illus-black-write");
          }

          &.plan {
            @include icons.ico("illus-black-plan");
          }

          &.chart {
            @include icons.ico("illus-black-chart");
          }

          &.google {
            @include icons.ico("illus-black-google");
          }

          &.searchEngine {
            @include icons.ico("illus-black-search-engine");
          }

          &.funnel {
            width: 404px;
            height: 190px;

            @include icons.ico("illus-black-funnel");
          }

          &.autopilot {
            width: 358px;
            height: 100px;

            @include icons.ico("illus-black-autopilot");
          }

          &.chartPurple {
            @include icons.ico("illus-black-chart-purple");
          }

          &.languages {
            @include icons.ico("illus-black-languages");
          }
        }
      }

      .imageContainer {
        width: 100%;
        position: relative;
        bottom: -25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        z-index: 1;

        .image {
          width: 100%;
          border-radius: 12px 12px 0px 0px;
        }
      }

      .shadow {
        width: 84px;
        height: 84px;
        background: var(--web-shadow-green);
        filter: blur(85px);
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &.colorBlue {
        background: var(--web-background-grid-blue);

        .shadow {
          background: var(--web-blue);
        }
      }

      &.colorYellow {
        background: var(--web-background-grid-yellow);

        .shadow {
          background: var(--web-yellow);
          filter: blur(85px);
        }

        .title {
          .step {
            .stepShadow {
              box-shadow: 0 0 20px 8px var(--web-yellow);
            }

            .text {
              color: var(--web-yellow);
            }
          }
        }
      }

      &.colorWhite {
        background: var(--web-background-grid-white);

        .shadow {
          background: var(--white);
          filter: blur(85px);
        }
      }

      &.colorPurple {
        background: var(--web-background-grid-purple);

        .shadow {
          background: var(--web-purple);
          filter: blur(85px);
        }
      }

      &.shadowBottomCenter {
        .shadow {
          left: calc(50% - 60px);
          bottom: 0;
          width: 120px;
          height: 58px;
        }
      }

      &.shadowLeftCenter {
        .shadow {
          left: 0;
          top: calc(50% - 60px);
          bottom: 0;
          width: 75px;
          height: 120px;
        }
      }

      &.shadowNone {
        .shadow {
          display: none;
        }
      }

      &.shadowMid {
        .shadow {
          width: 308px;
          height: 76px;
          left: calc(50% - 154px);
          top: calc(50% - 38px);
          bottom: unset;
          right: unset;
        }
      }

      &.sizeLarge, &.sizeLargeCenter {
        width: 100%;

        &.sizeLargeCenter {
          gap: 20px;
          text-align: center;

          .title {
            justify-content: center;
          }
        }

        .image {
          height: 330px;
          width: unset;
        }
      }

      &.sizeMid {
        width: 50%;
        flex-direction: row;
        gap: 25px;
      }

      &.sizeMidColumn {
        width: 50%;
        gap: 25px;

        .title {
          justify-content: center;
        }

        .description {
          text-align: center;
        }

        .iconContainer {
          width: 100%;
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      &.sizeLarge, &.sizeLargeCenter {
        width: 100%;

        &.sizeLargeCenter {
          gap: 20px;
          text-align: center;

          .title {
            justify-content: center;
          }
        }

        .image {
          height: 330px;
          width: unset;
        }
      }
    }
  }

  @media screen and (max-width: 930px) {
    .line {
      flex-direction: column;

      .entry {
        flex-direction: column !important;
        width: calc(100% - 50px) !important;

        .image {
          width: 100% !important;
          height: unset !important;
        }

        .autopilot {
          width: 280px !important;
          height: 78px !important;
        }

        .funnel {
          width: 280px !important;
          height: 131px !important;
        }
      }
    }
  }
}