@use "src/sedestral/sass/icons/icons";

.globalButtonCheckBox {
  border: 2px solid var(--grey-sur);
  padding: 3px;
  border-radius: 100%;
  position: relative;
  width: fit-content;
  cursor: pointer;

  &.checker {
    .caret {
      filter: var(--icon-white);

      @include icons.ico("black-checked");
    }

    &[unchecked] {
      .caret {
        filter: var(--icon-grey-dark);
      }

      &:hover {
        .caret {
          @include icons.ico("black-checked");
        }
      }
    }

    &[checked] {
      background: var(--black);
    }
  }

  .caret {
    height: 8px;
    width: 8px;
    background: var(--white);
    border-radius: 100%;
  }

  &[unchecked] {
    &:hover {
      .caret {
        background: var(--grey-sur);
      }
    }
  }

  &[checked] {
    border-color: transparent;
    background: var(--green);
  }
}