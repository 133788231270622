@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/effects";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/icons/icons";

.componentSite {
  margin-bottom: 2px;
  border-radius: 14px;
  padding: 8px 4px;
  width: calc(100% - 8px);
  transition: 0.1s;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  @include effects.hover-alpha;

  .avatarContainer {
    height: 26px;
    width: 26px;
    float: left;
    position: relative;

    .notifs {
      height: 14px;
      width: 14px;
      font-size: 10px;
      border: var(--white) 2px solid;
      color: var(--white);
      top: -4px;
      right: -4px;
      border-radius: 100%;
      background: var(--red);
      position: absolute;

      @include positioning.flex-globally;
      @include texts.font("semi-bold");
    }
  }

  .content {
    margin-top: -4px;
    margin-left: 15px;
    float: left;
    display: flex;
    align-items: center;

    .name {
      position: relative;

      @include texts.font("bold");
      @include positioning.clear;

      .text {
        float: left;
        width: 154px;
        overflow: hidden !important;
        overflow-wrap: normal;
        text-overflow: ellipsis;
        white-space: nowrap !important;
      }
    }
  }

  .checked {
    height: 14px;
    width: 14px;
    top: 19px;
    right: 10px;
    position: absolute;

    @include icons.ico("black-checked");
  }
}

