import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {
    Component
} from "../../../../sedestral/framework/interface/component/Component";
import {WebsiteContentScheduleComponent} from "../components/content/pages/schedule/WebsiteContentScheduleComponent";

export class WebsiteSchedulePage extends WebsitePageComponent {

    constructor() {
        super();
        this.updateMetas("schedule");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentScheduleComponent();
    }

    onHash(): void {

    }
}