import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "../statistics/website-content-statistics.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {WebsiteSliderLeftComponent} from "../../slider/left/WebsiteSliderLeftComponent";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteColumnsGridWhiteComponent} from "../../columns/grid/white/WebsiteColumnsGridWhiteComponent";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {WebsiteReviewsComponent} from "../../reviews/WebsiteReviewsComponent";
import {CustomerPopup} from "../../../../../../panel/components/components/customer/popup/types/CustomerPopup";
import {Network} from "../../../../../../../network/Network";
import {WebsiteBlackPresentationComponent} from "../../../black/presentation/WebsiteBlackPresentationComponent";
import {WebsiteBlackStepsComponent} from "../../../black/steps/WebsiteBlackStepsComponent";
import {WebsiteBlackCasesComponent} from "../../../black/cases/WebsiteBlackCasesComponent";
import {WebsiteBlackWordsComponent} from "../../../black/words/WebsiteBlackWordsComponent";
import {WebsiteBlackPlatformComponent} from "../../../black/platform/WebsiteBlackPlatformComponent";
import {WebsiteBlackFlowComponent} from "../../../black/flow/WebsiteBlackFlowComponent";

export class WebsiteContentBlogWordpressComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentStatistics}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPresentationComponent({
                            startText: Resources.t("words.beginFree"),
                            center: true,
                            title: Resources.t("words.wordpressExtensionTraffic"),
                            description: Resources.t("words.wordpressExtensionDescription"),
                            partnerIcon: "wordpress",
                            secondButton: false
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackStepsComponent({
                            title: Resources.t("words.generationSteps"),
                            icon: "seo",
                            center: true,
                            contents: [
                                {
                                    name: Resources.t("words.keyword"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-e.mp4"),
                                    duration: 14000
                                },
                                {
                                    name: Resources.t("words.instructions"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-f.mp4"),
                                    duration: 8000
                                },
                                {
                                    name: Resources.t("words.plan"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-g.mp4"),
                                    duration: 21000
                                },
                                {
                                    name: Resources.t("words.writing"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-h.mp4"),
                                    duration: 15000
                                },
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteSliderLeftComponent(Resources.t("words.howItWorks"), [
                            {
                                icon: "draft",
                                title: Resources.t("words.instructionsTitle"),
                                desc: Resources.t("words.instructionsDescription"),
                                image: NetworkVendor.image("blog-h-1.webp")
                            }, {
                                icon: "paragraph",
                                title: Resources.t("words.proposalsTitle"),
                                desc: Resources.t("words.proposalsDescription"),
                                image: NetworkVendor.image("blog-h-2.webp")
                            }, {
                                icon: "section",
                                title: Resources.t("words.planTitle"),
                                desc: Resources.t("words.planDescription"),
                                image: NetworkVendor.image("blog-h-3.webp")
                            }, {
                                icon: "pensil",
                                title: Resources.t("words.writingTitle"),
                                desc: Resources.t("words.writingDescription"),
                                image: NetworkVendor.image("blog-h-4.webp")
                            }]
                        ))}
                    </div>
                    <div class="${r.reportsContainer}">
                        <div class="${r.reports}">
                            <div class="${r.right}">
                                <img class="${r.image}" src="${NetworkVendor.image("blog-e.webp")}"
                                     alt="${Resources.t("words.styleCloning")}" draggable="false"
                                     width="380" height="347.51"
                                />
                            </div>
                            <div class="${r.left}">
                                <h2 class="${r.title}">
                                    ${Resources.t("words.keepWritingStyleTitle")}
                                </h2>
                                <p class="${r.subtitle}">
                                    ${Resources.t("words.keepWritingStyleText")}
                                </p>
                                <img class="${r.line}" src="${NetworkVendor.illus("separator-line.svg")}"
                                     alt="${Resources.t("words.separationLine")}" draggable="false"/>
                            </div>
                        </div>
                        <div class="${r.container}">
                            ${this.draw(new WebsitePresentationVerticalComponent({
                                title: Resources.t("words.explainContextToAI"),
                                description: Resources.t("words.contextFeatureText"),
                                image: NetworkVendor.image("blog-f.webp"),
                                color: "black",
                                size: "medium",
                                border: "border"
                            }))}
                        </div>
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                                    theme: "black",
                                    "questions": [
                                        {
                                            "question": Resources.t("words.doesGooglePenalizeAiContent"),
                                            "response": Resources.t("words.googleDoesNotPenalizeAiContent")
                                        },
                                        {
                                            "question": Resources.t("words.seoOptimizationQuestion"),
                                            "response": Resources.t("words.seoOptimizationResponse")
                                        },
                                        {
                                            "question": Resources.t("words.platformConnectionQuestion"),
                                            "response": Resources.t("words.platformConnectionResponse")
                                        },
                                        {
                                            "question": Resources.t("words.smartBlogAdvantagesQuestion"),
                                            "response": Resources.t("words.smartBlogAdvantagesResponse")
                                        }
                                    ]
                                }
                        ))}
                    </div>
                </div>
                <div class="${s.white}">
                    <div class="${s.content}">
                        <div class="${s.container}">
                            ${this.draw(new WebsiteBlackFlowComponent({
                                title: Resources.t("words.bestSeoAlly"),
                                bigTitle: Resources.t("words.completeWordpressExtension"),
                                image: NetworkVendor.image("blog-n.webp"),
                                texts: {
                                    canCollapse: true,
                                    entries: [
                                        {
                                            title: Resources.t("words.optimizeExistingSeoWordpress"),
                                            description: Resources.t("words.optimizeExistingSeoDescriptionWordpress"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        },
                                        {
                                            title: Resources.t("words.createEngagingContent"),
                                            description: Resources.t("words.createEngagingContentDescription"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        },
                                        {
                                            title: Resources.t("words.competitorAnalysis"),
                                            description: Resources.t("words.competitorAnalysisDescription"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        },
                                        {
                                            title: Resources.t("words.autopilot"),
                                            description: Resources.t("words.delegateContentCreationDescription"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        }
                                    ]
                                }
                            }))}
                        </div>
                    </div>
                </div>
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPlatformComponent({
                            title: Resources.t("words.ourPlatform"),
                            description: Resources.t("words.seoToolDescriptionWordpress"),
                            grid: {
                                lines: [{
                                    entries: [{
                                        title: Resources.t("words.automaticPilot"),
                                        description: Resources.t("words.automaticPilotDescription"),
                                        type: "icon",
                                        icon: "autopilot",
                                        size: "sizeMidColumn",
                                        color: "colorBlue",
                                        shadowPosition: "shadowMid"
                                    }, {
                                        title: Resources.t("words.workInAnyLanguage"),
                                        description: Resources.t("words.workInAnyLanguageDescription"),
                                        type: "icon",
                                        icon: "languages",
                                        size: "sizeMidColumn",
                                        color: "colorYellow",
                                        shadowPosition: "shadowMid"
                                    }]
                                }]
                            }
                        }))}
                                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackWordsComponent())}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        CustomerPopup.blog();
        super.commit();
    }
}
