@use "src/sedestral/sass/mixins/positioning";

.componentWebsiteContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .white {
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 40px 0;
    border-radius: 17px;
    z-index: 1;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 70px;

    @include positioning.webResponsive;

    .container {
      width: 100%;
    }
  }

  .webContent {

  }

  @media (max-width: 750px) {
    .content {
      gap: 60px;
    }
  }
}