import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-image-skewed.scss";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteImageSkewedComponent extends Component {

    public shadow: string;

    constructor(p: { link: string, shadow: string, width: string, height: string }) {
        super();
        this.shadow = p.shadow;
        this.type = "IMG";
        //language=HTML
        this.template = `
            <img class="${s.componentWebsiteImageSkewed} ${s[this.shadow]}"
                 src="${p.link}"
                 alt="${Resources.t("words.presentationImage")}"
                 width="${p.width}"
                 height="${p.height}"
            />
        `;
    }
}