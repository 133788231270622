import * as s from "./button-check-box.scss";
import {ButtonCheckComponent} from "../ButtonCheckComponent";

export class ButtonCheckBoxComponent extends ButtonCheckComponent {
    constructor(checked: boolean, checker?: boolean) {
        super(checked);
        //language=HTML
        this.template = `
            <div btnCheckBox class="${s.globalButtonCheckBox} ${checker ? s.checker : ``}" ${this.state()}>
                <div btnCheckBoxCaret class="${s.caret}"></div>
            </div>
        `;
    }
}