import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteTableComponent} from "../../table/WebsiteTableComponent";

export class WebsiteContentCgvComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteTableComponent({
                            clauses: Resources.t("words.generalConditionsSale.clauses"),
                            title: Resources.t("words.generalConditionsSale.conditions"),
                            subtitle: Resources.t("words.generalConditionsSale.date")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}