import {
    Component
} from "../../../../../../../sedestral/framework/interface/component/Component";
import * as s from "../website-content.scss";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {CustomerPopup} from "../../../../../../panel/components/components/customer/popup/types/CustomerPopup";
import {WebsiteBlackStepsComponent} from "../../../black/steps/WebsiteBlackStepsComponent";
import {WebsiteBlackPresentationComponent} from "../../../black/presentation/WebsiteBlackPresentationComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteBlackIndicatorsComponent} from "../../../black/indicators/WebsiteBlackIndicatorsComponent";
import {WebsiteBlackAllieComponent} from "../../../black/allie/WebsiteBlackAllieComponent";

export class WebsiteContentBlogAnalysisComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPresentationComponent({
                            startText: Resources.t("words.beginFree"),
                            center: true,
                            title: Resources.t("words.indicatorsGiveEdge"),
                            description: Resources.t("words.sedestralTrackingDescription"),
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackStepsComponent({
                            title: Resources.t("words.steps"),
                            icon: "seo",
                            contents: [
                                {
                                    name: Resources.t("words.rankVisualization"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-l.mp4"),
                                    duration: 10000
                                },
                                {
                                    name: Resources.t("words.competitorComparison"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-m.mp4"),
                                    duration: 14000
                                }
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackIndicatorsComponent({
                            description: Resources.t("words.cannotImproveWhatNotSeen"),
                            content: Resources.t("words.sedestralReviewDescription"),
                        }))}
                    </div>
                </div>
                <div class="${s.white}">
                    <div class="${s.content}">
                        <div class="${s.container}">
                            ${this.draw(new WebsiteBlackAllieComponent())}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        CustomerPopup.blog();
        super.commit();
    }
}

