import {
    Component
} from "../../../../../../sedestral/framework/interface/component/Component";
import * as s from "./website-black-platform.scss";
import {WebsiteBlackGridComponent} from "../grid/WebsiteBlackGridComponent";
import {IWebsiteBlackPlatform} from "./types/IWebsiteBlackPlatform";

export class WebsiteBlackPlatformComponent extends Component {
    constructor(platform: IWebsiteBlackPlatform) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackPlatform}">
                <div class="${s.title}">
                    ${platform.title}
                </div>
                <div class="${s.description}">
                    <div class="${s.shadow}"></div>
                    <div class="${s.content}">
                        ${platform.description}
                    </div>
                </div>
                <div class="${s.grid}">
                    ${this.draw(new WebsiteBlackGridComponent(platform.grid))}
                </div>
            </div>
        `;
    }
}