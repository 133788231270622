@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentWebsiteBlackAllie {
  display: flex;
  flex-direction: column;
  color: var(--black);
  gap: 30px;

  .title {
    color: var(--web-purple-text);
    text-transform: uppercase;
    font-size: 16px;

    @include texts.font("semi-bold");
  }

  .bigTitle {
    max-width: 900px;
    font-size: 26px;

    @include texts.font("semi-bold");
  }

  .cases {
    display: flex;
    gap: 20px;

    .case {
      padding: 40px 30px;
      display: flex;
      width: 33.33%;
      gap: 25px;
      flex-direction: column;
      position: relative;
      border-radius: 16px;
      background: var(--grey-sur-light);

      &.full {
        width: 50%;
        padding: 35px 40px 0px 40px;

        .name {
          font-size: 20px;
        }
      }

      .name {
        gap: 15px;
        font-size: 17px;
        display: flex;
        align-items: center;

        @include texts.font("semi-bold");

        .icon {
          height: 28px;
          width: 28px;
          flex: 0 0 28px;

          &.chart {
            @include icons.ico("black-line-chart");
          }

          &.speedometer {
            @include icons.ico("black-speedometer");
          }

          &.refresh {
            @include icons.ico("black-refresh");
          }
        }
      }

      .description {
        font-size: 14px;
      }

      .icons {
        display: flex;
        gap: 25px;

        .illus {
          height: 249px;
          width: 200px;
          flex: 0 0 200px;
          bottom: 0;

          &.optimize {
            @include icons.ico("illus-black-optimize");
          }

          &.plan {
            @include icons.ico("illus-black-ai-plan");
          }
        }
      }
    }
  }

  .zig {
    width: 70px;
    height: 17px;
    margin-top: 60px;

    @include icons.ico("illus-black-zig");
  }

  @media screen and (max-width: 930px) {
    .cases {
      flex-wrap: wrap;

      .case {
        width: calc(100% - 80px) !important;

        .illus {
          flex: 0 0 80px !important;
          width: 80px !important;
          height: 100px !important;
        }
      }
    }
  }
}